<template>
  <div class="container-fluid">
    <div class="header-icon">
      <div class="header">
        <h1 class="zikir">
          ZİKİR
        </h1>
        <h1 class="online">
          ONLINE
        </h1>
      </div>
      <div class="user float-end">
        <router-link to="/kullanici" class="float-end far fa-user text-black"></router-link>
      </div>
    </div>
    <div class="body-container">
      <div class="empty"
           v-show="!hatimler">
        Listelenecek Hatim Bulunamadı
      </div>
      <div class="liste-acordion"
           v-show="hatimler"
      >
        <div class="baslik">
          <div class="hatim-listesi">Hatim Listesi</div>
        </div>
        <div>
          <div class="accordion">
            <div class="body" style="max-height: 1000px;overflow-y:scroll">
              <div class="accordion-item"
                   v-for="(hatim,i) in hatimler"
                   :key="hatim">
                <h2 class="accordion-header">
                  <button
                      @click="assignHatimTur(hatim)"
                      class="accordion-button collapsed"
                      type="button"
                      data-mdb-toggle="collapse"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                      :data-mdb-target="`#collapse${i+1}`"
                  >
                    <i
                        class="icon-user fas fa-user fs-5"
                        v-show="hatim.toplu_hatim_mi===false&&hatim.tur!=='Serbest Okuma'"
                    >

                    </i>
                    <i
                        class="icon-user fas fa-users fs-5 text-success"
                        v-show="hatim.toplu_hatim_mi===true"
                    >

                    </i>
                    <i
                        class="icon-user fas fa-book-reader fs-5"
                        v-show="hatim.toplu_hatim_mi===false&&hatim.tur==='Serbest Okuma'">

                    </i>
                    {{ hatim.isim }}
                  </button>
                </h2>
                <div
                    :id="`collapse${i+1}`"
                    class="accordion-collapse collapse"
                    style="padding-left: 10px;padding-right: 10px"
                    aria-labelledby="headingOne"
                    data-mdb-parent="#accordionExample"
                >
                  <div class="justify-content-start flex-column">
                    <div class="justify-content-start d-flex mt-2"
                         style="font-size: 14px;margin-bottom: -10px">
                      <span>{{ hatim.tur }} Hatmi</span></div>
                    <div class="okunan" v-show="hatim.tur!=='Serbest Okuma'">
                      <div
                          class="okudugunuz float-start"
                          v-for="hatimOkuma in hatimOkumaAyniUserIDliHatimler"
                          :key="hatimOkuma" v-show="hatimOkuma.hatim_id===hatim.id"
                      >
                        Okuduğunuz:{{ hatimOkuma.toplam_okunan }}
                      </div>
                      <div
                          class="toplam-okunan float-end"
                          v-if="turValue===0">
                        {{ hatim.okunan }}/{{ hatim.adet }} Cüz
                      </div>
                      <div
                          class="toplam-okunan float-end"
                          v-if="turValue===1">{{ hatim.okunan }}/{{ hatim.adet }} Sûre
                      </div>
                      <div
                          class="toplam-okunan float-end"
                          v-if="turValue===2">
                        {{ hatim.okunan }}/{{ hatim.adet }} Cevşen
                      </div>
                      <div
                          class="toplam-okunan float-end"
                          v-if="turValue===3">
                        {{ hatim.okunan }}/{{ hatim.adet }}
                      </div>
                    </div>
                    <div class="progress justify-content-start"
                         v-show="hatim.tur!=='Serbest Okuma'">
                      <div class="progress-bar" role="progressbar"
                           :style="{width:(100)*(hatim.okunan/hatim.adet)+'%'}"
                           :aria-valuenow="hatim.okunan" :aria-valuemin="0"
                           :aria-valuemax="100">
                      </div>
                    </div>
                    <div class="date" style="margin-top: 40px; margin-left: 1px">
                      <i class="calendar bi bi-calendar4 fs-6 float-start"
                         style="margin-top: -7px"></i>
                      <div class="time-date">
                        <div class="date-icon float-start">
                          {{ moment(hatim.bitis).format("DD.MM.YYYY") }}
                        </div>
                      </div>
                    </div>
                    <br>
                    <div class="mt-3 mb-3 justify-content-between d-flex"
                         style="max-width: 312px;width:100%;margin-left: 1px">
                      <div class="mt-1"><i class="far fa-clock fs-6"></i></div>
                      <div class="time-date" style="margin-top: 6px">
                        <div class="date-icon float-start">
                          {{ moment(hatim.bitis).format("HH:mm") }}
                        </div>
                      </div>
                      <div>
                        <button
                            class="submit btn"
                            @click="Router(hatim,i)"
                            type="button">
                          <i class="fas fa-chevron-right fs-7 text-white"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="buttons">
          <router-link
              class="buton-1 btn" to="/hatim-olustur">Yeni Hatim Başlat
            <i class="plus-icon fas fa-plus-circle text-capitalize"></i>
          </router-link>
          <router-link
              class="buton-2 btn"
              to="/serbest-hatim-olustur"
          >Serbest Okuma Başlat
            <i
                class="plus-icon fas fa-plus-circle"></i>
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import ClipboardJS from "clipboard";
import moment from "moment-timezone";
import {ErrorMessage} from "@/excore/Popup";
import {LinkMessage} from "@/excore/Toast";
import {getIntervalID} from "@/store/Interval";
import apiservice from "@/exservice/apiservice";
import {removeTokens} from "@/store/Auth";

export default {
  name: "MainPage",
  setup() {
    const router = useRouter();
    const times = ref([]);
    const user = ref({});
    const turValue = ref(0);
    const dates = ref([]);
    const hatimler = ref([]);
    const hatimOkumaAyniUserIDliHatimler = ref([]);
    const benimOkudugumAdetler = ref([]);
    new ClipboardJS('.clipboard');
    const Router = async (hatim) => {
      if (hatim.tur === "Serbest Okuma") {
        await router.push(`/serbest-hatim/${hatim.id}`);
        return;
      }
      if (hatim.okunacaklar.length === 1) {
        await router.push(`/tekli-hatim/${hatim.id}`);
      }
      if (hatim.okunacaklar.length > 1) {
        await router.push(`/coklu-hatim/${hatim.id}`);
      }
    }
    const assignHatimTur = (hatim) => {
      switch (hatim.tur) {
        case "Kur'an-ı Kerim(Cüz)":
          turValue.value = 0;
          break;
        case "Kur'an-ı Kerim(Sûre)":
          turValue.value = 1;
          break;
        case "Cevşen":
          turValue.value = 2;
          break;
        default:
          turValue.value = 3;
          break;
      }
    }
    onMounted(async () => {
      if (getIntervalID()) {
        window.clearInterval(parseInt(getIntervalID()));
      }
      const [response, error] = await apiservice.get("user/me")
      if (error) {
        removeTokens();
        await router.push("/giris")
        return
      }
      user.value = response.data;
      hatimler.value = response.data.hatimler;
      if (!hatimler.value) {
        return
      }
      const [resp, err] = await apiservice.get(`hatim-okumalarim`);
      if (err) {
        return ErrorMessage(err);
      }
      hatimOkumaAyniUserIDliHatimler.value = resp.data;
    })
    const sweetAlert = (kod) => {
      navigator.clipboard.writeText('https://zikir.hayrat.dev/kod/' + kod)
      LinkMessage();
    }
    return {
      Router,
      sweetAlert,
      assignHatimTur,
      moment,
      hatimler,
      turValue,
      times,
      user,
      hatimOkumaAyniUserIDliHatimler,
      benimOkudugumAdetler,
      dates,
    }
  }
}
</script>

<style scoped>
.header {
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 42px;
}

.online {
  background: -webkit-linear-gradient(270deg, #0868C9 3.98%, #3498DC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  font-size: 20px;
}

.zikir {
  font-weight: 600;
  font-size: 20px;
  color: #0A2540;
}

.body {
  margin: 60px auto 0 auto;
  max-width: 500px;
}

.hatim-listesi {
  left: 0;
  color: #0868C9;
  font-weight: 600;
  font-size: 24px;
}

.accordion-item {
  background: #FFFFFF;
  /* Theme/Grey */
  border: 2px solid #AAAAAA;
  border-radius: 4px;
  margin-bottom: 8px;
  max-width: 350px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.accordion-header {
  font-size: 14px;
  color: #111111;
  font-weight: 400;
}

.progress {
  max-width: 310px;
  width: 100%;
  height: 16px;
  margin-bottom: -7.5px;
  background-color: #FFFFFF;
  border: 1px solid #0868C9;
  border-radius: 4px;
}

.progress-bar {
  background: -webkit-linear-gradient(270deg, #0868C9 3.98%, #3498DC 100%);
  border-radius: 2px;
}

.submit {
  background: -webkit-linear-gradient(270deg, #0868C9 3.98%, #3498DC 100%);
  color: white;
  max-width: 54px;
  width: 100%;
  height: 32px;
  padding-top: 8px;
}

.baslik {
  max-width: 350px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #0868C9;
  font-weight: 600;
  font-size: 24px;
  margin-top: 40px;
}

.hatim-listesi {
  float: left;
  margin-top: -35px;
}

.buttons {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.buton-1 {
  max-width: 242px;
  margin-bottom: 10px;
  padding-top: 5.5px;
  text-transform: none;
  width: 100%;
  height: 40px;
  border: 2px solid transparent;
  background: linear-gradient(white, white) padding-box,
  linear-gradient(270deg, #0868C9 3.98%, #3498DC 100%) border-box;
  color: #3498DB;
  /*border-image: linear-gradient(270deg, #0868C9 3.98%, #3498DC 100%) 5;*/
  font-size: 16px;
  font-weight: 600;
}

.buton-2 {
  max-width: 242px;
  padding-top: 7px;
  text-transform: none;
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  color: white;
  background: linear-gradient(270deg, #0868C9 3.98%, #3498DC 100%);
  border-radius: 4px;
  margin-bottom: 32px;
}

.empty {
  max-width: 350px;
  width: 100%;
  height: 116px;
  background-color: #F4F4F4;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 400;
  font-size: 16px;
  color: #848484;
  margin: 50px auto 250px auto;
}

.far {
  margin-top: -10px;
}

.user {
  max-width: 124px;
  width: 100%;
  margin-top: -20px;
  padding-right: 5px;
}

.body-container {
  height: 90%;
  width: 100%;
  margin-top: 60px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  position: absolute;
  padding-right: 10px;
  padding-left: 10px;
  top: 0;
  left: 0;
}

.icon-user {
  color: #0868C9;
  margin-right: 10px;
  margin-left: -10px;
  margin-top: -3px;
}

.hatim-tur {
  font-size: 14px;
  color: #111111;
  margin-top: -10px;
  margin-left: -10px;
}

.okunan {
  max-width: 310px;
  margin-top: 30px;
  height: 10px;
}

.okudugunuz {
  height: 10px;
  margin-top: -10px;
  max-width: 234px;
  font-size: 12px;
  font-weight: 500;
  color: #0868C9;
}

.toplam-okunan {
  height: 10px;
  margin-top: -10px;
  max-width: 234px;
  font-size: 12px;
  font-weight: 500;
  color: #0868C9;
}

.date {
  margin-top: 20px;
}

.time-date {
  font-size: 12px;
  font-weight: 400;
  color: #111111;
  max-width: 400px;
  width: 100%;
  margin-right: 10px;
}

.date-icon {
  margin-left: 7px;
}

.calendar-icon {
  padding-top: 7px;
}

.plus-icon {
  margin-left: 10px;
}

.liste-acordion {
  margin-top: 20px;
}

.header-icon {
  max-width: 350px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
</style>