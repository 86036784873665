import Swal from "sweetalert2";

export const SuccessMessage = (text) => {
    Swal.fire({
        title: 'İşlem Başarılı',
        text: text,
        icon: 'success',
        confirmButtonText: 'Tamam',
        confirmButtonColor: "#0868C9"
    })
}
export const ErrorMessage = (text) => {
    Swal.fire({
        title: 'Hata!',
        text: text,
        icon: 'error',
        confirmButtonText: 'Tamam',
        confirmButtonColor: "#0868C9"
    })
}


export function WarningPopup(html, confirmText, cancelText) {
    return Swal.fire({
        title: "Dikkat",
        html: html,
        icon: "warning",
        showCancelButton: true,
        // confirmButtonColor: "#3085d6",
        // cancelButtonColor: "#cc3636",
        confirmButtonText: confirmText,
        cancelButtonText: cancelText,
    });
}