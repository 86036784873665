import { createRouter, createWebHistory } from 'vue-router'
import MainPage from "@/views/MainPage";
import "nprogress/nprogress.css";
import nProgress from "nprogress";

const routes = [
  {
    path: '/',
    name: 'Anasayfa',
    component: MainPage
  },
  {
    path: '/gecmis-hatimler',
    name: 'Gecmis Hatimler',
    component: () => import('../views/hatim/GecmisHatimler.vue')
  },
  {
    path: '/kullanici',
    name: 'Hesabım',
    component: () => import('../views/user/Account.vue')
  },
  {
    path: '/hatim-olustur',
    name: 'Yeni Hatim',
    component: () => import('../views/HatimOlustur.vue')
  },
  {
    path: '/serbest-hatim-olustur',
    name: 'Serbest Okuma',
    component: () => import('../views/SerbestHatimOlustur.vue')
  },
  {
    path: '/serbest-hatim/:id',
    name: 'Serbest Okuma Duzenle',
    component: () => import('../views/hatim/SerbestHatimDuzenle.vue')
  },
  {
    path: '/hatim',
    name: 'Hatim',
    component: () => import('../views/Hatim.vue')
  },
  {
    path: '/serbest-hatim-ekle/:id',
    name: 'Serbest Okuma Ekle',
    component: () => import('../views/hatim/SerbestHatimEkle.vue')
  },
  {
    path: '/tekli-hatim/:id',
    name: 'Tekli Hatim Duzenle',
    component: () => import('../views/hatim/TekliHatimDuzenle.vue')
  },
  {
    path: '/gecmis-hatim-duzenle/:id',
    name: 'Gecmis Hatim Duzenle',
    component: () => import('../views/hatim/GecmisHatimDuzenle.vue')
  },
  {
    path: '/coklu-hatim/:id',
    name: 'Coklu Hatim Duzenle',
    component: () => import('../views/hatim/CokluHatimDuzenle.vue')
  },
  {
    path: '/giris',
    name: 'Giris Yap',
    component: () => import('../views/user/SignIn.vue')
  },
  {
    path: '/sifremi-unuttum',
    name: 'Sifremi-Unuttum',
    component: () => import('../views/user/ForgotPassword.vue')
  },
  {
    path: '/bilgilerimi-guncelle',
    name: 'Bilgilerimi Guncelle',
    component: () => import('../views/user/UpdateAccount.vue')
  },
  {
    path: '/kayit',
    name: 'Kayit ol',
    component: () => import('../views/user/SignUp.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve((to, from, next) => {
  if (to.name) {
    nProgress.start();
  }
  document.title = 'Zikir Online-'+to.name;
  next();
});

router.afterEach(() => {
  nProgress.done();
});

export default router
