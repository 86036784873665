import axios from "axios";
import {getAccessToken} from "@/store/Auth";

const apiService = axios.create({
    baseURL: 'https://zikir.hayrat.dev/api/',
});
apiService.interceptors.request.use(
    (config) => {
        const token = getAccessToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

async function handleError(error) {
    if (error.response.data.error_message) {
        if (error?.response?.status === 401) {
            document.href = "/giris"
            return [null, "Oturum süreniz Dolmuştur"];
        } else if (error?.response?.status === 500) {
            return [null, "Sunucu içi hata"];
        } else {
            return [null, error.response.data.error_message];
        }
    }
    return Promise.reject(error);
}

export default {
    async get(endpoint) {
        try {
            const response = await apiService.get(endpoint);
            return [response.data, null];
        } catch (error) {
            return handleError(error);
        }
    },
    async post(endpoint, data) {
        try {
            const response = await apiService.post(endpoint, data);
            return [response.data, null];
        } catch (error) {
            return handleError(error);
        }
    },
    async put(endpoint, data) {
        try {
            const response = await apiService.put(endpoint, data);
            return [response.data, null];
        } catch (error) {
            return handleError(error);
        }
    },
    async delete(endpoint) {
        try {
            const response = await apiService.delete(endpoint);
            return [response.data, null];
        } catch (error) {
            return handleError(error);
        }
    }
};
