import Swal from "sweetalert2";

export const ToastMessage = (text, icon, duration) => {
    const toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: duration,
        width: '343px',
        iconHtml: icon,
        iconColor: '#0868C9',
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    toast.fire({
        title: text,
    })
}

export const LinkMessage = () => {
    const toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 1000,
        width: '343px',
        iconHtml: '<i class="bi bi-link-45deg fa-sm" style="margin-top: -2.5px"></i>',
        iconColor: '#0868C9',
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    toast.fire({
        title: "Link Kopyalandı",
    })
}

export const HatimCompletedMessage = () => {
    const toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 2000,
        width: '343px',
        iconHtml: '<i class="fas fa-check fa-fw" style="margin-top: -3.5px"></i>',
        iconColor: '#0868C9',
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    toast.fire({
        title: "Hatim Tamamlandı",
    })
}


export const ReadinCompletedMessage = () => {
    const toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 1000,
        width: '350px',
        iconHtml: '<i class="bi bi-file-earmark-check fa-sm" style="margin-top: -4px"></i>',
        iconColor: '#0868C9',
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    toast.fire({
        title: "Okuma tamamlandı",
    })
}

export const AddingSuccessfulMessage = () => {
    const toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 500,
        width:'343px',
        iconHtml: '<i class="bi bi-file-earmark-plus fa-sm" style="margin-top: -4px"></i>',
        iconColor:'#0868C9',
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    toast.fire({
        title: "Ekleme Başarılı",
    })
}

export const ParticipantAddingSuccessfulMessage = () => {
    const toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 500,
        width: '343px',
        iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-add" viewBox="0 0 16 16">\n' +
            '  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"/>\n' +
            '  <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z"/>\n' +
            '</svg>',
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    toast.fire({
        title: "Ekleme başarılı",
    })
}

export const ParticipantRemovingSuccessfulMessage = () => {
    const toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 500,
        width: '343px',
        iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-x" viewBox="0 0 16 16">\n' +
            '  <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z"/>\n' +
            '  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-.646-4.854.646.647.646-.647a.5.5 0 0 1 .708.708l-.647.646.647.646a.5.5 0 0 1-.708.708l-.646-.647-.646.647a.5.5 0 0 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 .708-.708Z"/>\n' +
            '</svg>',
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    toast.fire({
        title: "Çıkarma başarılı",
    })
}


export const ReducingSuccessfulMessage = () => {
    const toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 500,
        width: '343px',
        iconHtml: '<i class="bi bi-file-earmark-minus fa-sm" style="margin-top: -4px"></i>',
        iconColor: '#0868C9',
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    toast.fire({
        title: "Azaltma Başarılı",
    })
}

export const OptionsUpdatedMessage = () => {
    const toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 1500,
        width: '343px',
        iconHtml: '<i class="bi bi-gear fa-sm" style="margin-top:-4px"></i>',
        iconColor: '#0868C9',
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    toast.fire({
        title: "Ayarlar güncellendi",
    })
}

export const HatimEndedMessage = () => {
    const toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 2000,
        width: '343px',
        iconHtml: '<i class="fas fa-check fa-fw" style="margin-top: -3.5px"></i>',
        iconColor: '#0868C9',
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    toast.fire({
        title: "Hatim Sonlandırıldı",
    })
}

export const ReadingSuccessfullyAddedMessage = () => {
    const toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 1000,
        width: '343px',
        iconHtml: '<i class="bi bi-file-earmark-plus fa-sm" style="margin-top: -4px"></i>',
        iconColor: '#0868C9',
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    toast.fire({
        title: "Okumanız Eklendi",
    })
}

export const HatimCreatedMessage = () => {
    const toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        showConfirmButton: false,
        timer: 1500,
        width: '343px',
        iconHtml: '<i class="fas fa-check fa-fw" style="margin-top: -3.5px"></i>',
        iconColor: '#0868C9',
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    toast.fire({
        title: "Hatim Oluşturuldu",
    })
}